import {ChangeEvent} from 'react';
import {Ad, AdSet, Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {SuspenseStatus} from './types';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from './package';
import { ExecutableAction } from "titan-ads/lib/shapes/ExecutableAction";

export interface TitanQueryConfig {
  shape:NodeShape,
  search?:string;
  limit?: number;
  offset?: number;
  sortBy?: {
    id: string;
    desc: boolean;
  };
  filters?: { field?: string; value?: any, fn?:(instance)=>boolean }[];
}

let timer;
export function debounce(func, timeout = 700) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}


export function useSuspense(promise: Promise<any>) {
  let status: SuspenseStatus = 'pending';

  let result;
  const suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    },
  );

  return {
    read() {
      switch (status) {
        case 'error':
        case 'pending':
          throw suspender;
        case 'success':
          return result;
      }
    },
  };
}
export type Quarter = 'Q1' | 'Q2' | 'Q3' | 'Q4';

export interface DMYQ {
  DAY: string;
  MONTH: string;
  QUARTER: Quarter;
  YEAR: string;
}

/**
 * Get the day, month, and year of a date.
 * @param date
 */
export function getDMYQ(date: Date): DMYQ {
  const YEAR = date.getFullYear().toString();
  const MONTH = zeroPad(date.getMonth() + 1, 2);
  const DAY = zeroPad(date.getDate(), 2);

  const QUARTER: Quarter = getQuarter(date.getMonth().toString());

  return {DAY, MONTH, QUARTER, YEAR};
}

/**
 * Being used for file creation, ensuring dates
 * are padded with a leading `0` to a certain length:
 *
 * ```
 * zeroPad(5, 2)  // returns "05"
 * zeroPad(12345, 3)  // returns "1234"
 * ```
 */
export function zeroPad(num: number, maxPad: number): string {
  return `${num}`.padStart(maxPad, '0');
}

export function getQuarter(month:string): Quarter {
  return `Q${Math.ceil(parseInt(month) / 3)}` as Quarter;
}

// export function generateAdName(
//   platform: Platform,
//   values: string[],
// ): [string[], string] {
//   const adNameIndex = getInputIndex(platform, 'Ad Name');

//   const adName = values.filter((_, i) => i !== adNameIndex).join(', ');
//   const newValues = values.map((v, i) => (i === adNameIndex ? adName : v));

//   return [newValues, adName];
// }

// Looks up existing campaigns, if one with the same name exists, then
// increment the unique identifier
export function generateUniqueCampaignTitle(
  campaigns: Campaign[],
  campaignName: string,
): string {
  const suffix: number = campaigns.filter((c) => {
    const startsWithSameCampaignName = c.name.startsWith(campaignName);

    // Need to do an additional check for special cases where an account
    // name might be equal to the beginning of a string, but have more to
    // it.  For example:
    //
    // alreadyExists = ["a b cdef 1", "a b c 1"]
    // toCreate = "a b c"
    //
    // We want to match cases where everything except the last number is
    // part of the campaign title
    if (startsWithSameCampaignName) {
      const unmatchedIsNumber = !Number.isNaN(
        c.name.replace(campaignName, '').trim(),
      );

      return unmatchedIsNumber;
    }
  }).length;

  return `${campaignName} ${suffix + 1}`;
}

/**
 * Retrieves the currently selected option's value of a given attribute on a
 * HTMLSelectElement
 * @param e The element event from which the attribute should be retrieved
 * @param attr The name of the attribute attached to the HTML element
 * @returns The value given to `attr` on the HTML element
 */
export function getAttribute(e: ChangeEvent<HTMLSelectElement>, attr: string) {
  return e.target[e.target.selectedIndex].getAttribute(attr);
}

/**
 * Unique-ify adset and campaign results
 */
export function dedupe(withDuplicates: any[] = [], callback): any {
  const seen = {};

  return withDuplicates.filter((item) => {
    const prop = callback(item);

    return seen.hasOwnProperty(prop) ? false : (seen[prop] = true);
  });
}

/**
 * AdSet prefixes are in the form "XY-aZ09", and we're interested in
 * extracting the "aZ09" part of the prefix
 * @param adSetPrefix
 * @returns
 */
export function extractIdFromAdSetPrefix(adSetPrefix: string) {
  return adSetPrefix.split('-')[1];
}

export const getShapeNameFromParams = (str) => {
  //example get Person from schema:person

  const lastWord = str.split(':').pop();
  const capitalizedLastWord =
    lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
  return capitalizedLastWord;
};

export const getShapeParamsFromUri = (str) => {
  //example get schema:person from https://data.lincd.org/module/lincd-schema/shape/person

  // Get the word between "module" and "/shape"
  const betweenLincdAndShape = str.match(/module\/(.*?)\/shape/);
  const wordBetween = betweenLincdAndShape ? betweenLincdAndShape[1] : null;

  // Get the last word after the last "/"
  const lastWord = str.substring(str.lastIndexOf('/') + 1);

  return wordBetween + ':' + lastWord;
};

interface LoadExecutableActionsParams {
  searchQuery: string;
  pageSize: number;
  pageIndex: number;
  sorting: TitanQueryConfig['sortBy'][];
  actionStatus?: number; // Optional parameter for filtering by status
}

export function loadExecutableAction({
  searchQuery,
  pageSize,
  pageIndex,
  sorting,
  actionStatus = 0, // Default status to 0 (for potential status)
}: LoadExecutableActionsParams) {
   //TODO: put this in a function 'loadExecutableActions(0 <-- actionStatus)', useEffect just calls the method
   return Server.call(packageName,'loadApprovalData',{
    shape: ExecutableAction.shape,
    search: searchQuery,
    limit: pageSize, // For example, limit to 10 items
    offset: pageIndex * pageSize, // Start from the first item
    sortBy: sorting.length > 0 
      ? sorting[0] 
      : undefined,
    filters: [
        { field: 'actionStatus', value: actionStatus }, //zero for potential status
      ] 
  } as TitanQueryConfig).then(data => {
    return { instances: data.instances, numInstances: data.numInstances };
  }).catch((err) => {
    console.error('Error load executable action data: ', err);
    throw err;
  })
}

interface LoadDataProps {
  searchQuery: string;
  pageSize: number;
  pageIndex: number;
  sorting: TitanQueryConfig['sortBy'][];
}

export function loadAdData({
  searchQuery,
  pageSize,
  pageIndex,
  sorting,
}: LoadDataProps) {
  return Server.call(packageName, 'loadAdManagementData', {
    shape: Ad.shape,
    search: searchQuery,
    limit: pageSize,
    offset: pageIndex * pageSize,
    sortBy: sorting.length > 0 ? sorting[0] : undefined,
  } as TitanQueryConfig).then(data => {
    return { instances: data.instances, numInstances: data.numInstances };
  });
}

export function loadAdSetData({
  searchQuery,
  pageSize,
  pageIndex,
  sorting,
}: LoadDataProps) {
  return Server.call(packageName, 'loadAdSetManagementData', {
    shape: AdSet.shape,
    search: searchQuery,
    limit: pageSize,
    offset: pageIndex * pageSize,
    sortBy: sorting.length > 0 ? sorting[0] : undefined,
  } as TitanQueryConfig).then(data => {
    return { instances: data.instances, numInstances: data.numInstances };
  });
}

export interface DynamicObject {
  [key: string]: any;
}

export interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}


// Reusable function to generate columns
export const generateCustomColumns = (instances: DynamicObject[]): CustomColumn[] => {
  const allKeys = new Set<string>();

  instances.forEach((instance) => {
    Object.keys(instance).forEach((key) => {
      if(key !== 'id')
      {
        allKeys.add(key)
      }
    });
  });

  return Array.from(allKeys).map((key) => ({
    label: key,
    property: {
      label: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase()),
    },
    renderCell: (row: DynamicObject) => {
      const value = row[key];
      return typeof value === 'object' && value !== null ? value.uri : value;
    },
  }));
};


export const renderNoDataMessage = (instances: DynamicObject[], statusType: string) => {
  if (instances && instances.length === 0) {
    return <h1>No {statusType}</h1>;
  }
  return null;
};

export function yyyyMMddToMMddyy(date: string) {
  const [year, month, day] = date.split('-');

  return `${month}/${day}/${year.slice(2)}`;
}