import React, {useEffect, useState, useCallback, useContext} from 'react'
import { Ad } from "titan-ads/lib/shapes/TitanShapes";
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { TitanQueryConfig, loadAdData , renderNoDataMessage } from '../utils';
import { Spinner } from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview'
import { debounce } from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import AdActionDropdown from '../components/AdActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface AdManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function AdManagement({ batchActions }: AdManagementProps) {
  const { isLight } = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const shape: NodeShape = Ad.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  }

  // const debouncedSetSearchQuery = useCallback(
  //   debounce((value) => setSearchQuery(value), 500), // 500ms debounce delay
  //   []
  // );

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    }, 500), // 500ms debounce delay
    []
  );

  async function fetchAdData() {
    try {
      const adData = await loadAdData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
      });

      setInstances(adData.instances);
      setTotalItems(adData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchAdData();
  },[pagination.pageIndex, pagination.pageSize, sorting, searchQuery])

  const customColumns = useCustomColumns(instances);

  return (
    <>
       {instances && customColumns ? (
        <InstanceOverview 
          instances={instances} 
          shape={shape} 
          customColumns={customColumns}
          pagination={pagination}
          totalItems={totalItems}
          onPaginationChange={setPagination} 
          sorting={sorting}
          onSortingChange={setSorting}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight} // Pass isLight to InstanceOverview
          batchActionDropdown={(selectedRows) => <AdActionDropdown selectedRows={selectedRows} onComplete={fetchAdData}/>}
        />) : (<Spinner />)}
    </>
      
  )
}

export default AdManagement