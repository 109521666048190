import React,{useEffect,useState} from 'react';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {CustomColumn,DynamicObject} from '../utils';

export function useCustomColumns(instances) {
  const [customColumns, setCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  useEffect(() => {
    if (instances && instances.length > 0) {

      // Collect all unique keys from all instances
      const allKeys = new Set<string>();

      instances.forEach((instance) => {
        Object.keys(instance).forEach((key) => {
          if(key !== 'id') {
            allKeys.add(key)
          }
        });
      });

      // Generate columns from all unique keys
      const columns = Array.from(allKeys)
        .map((key) => {
          return {
            label: key,
            property: {label: key
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^./, (str) => str.toUpperCase())},
            renderCell: (row: DynamicObject) => {
              const value = row[key];
              if(typeof value === 'string' && value.match(/(?:__|[*#])|\[(.*?)\]\(.*?\)/)) {
                //parse out link and title of link
                const [,title,url] = value.match(/\[(.*?)\]\((.*?)\)/);
                return <a href={url} target={'_blank'} rel="noreferrer">{
                  title
                }</a>

              }
              if(typeof value === 'boolean') {
                return value ? 'Yes' : 'No';
              }
              if(value instanceof NamedNode) {
                console.warn('NamedNode:',value);
                return value.uri;
              }
              if(value instanceof Shape) {
                console.warn('Shape detected:',value);
                return value.uri;
              }
              if (typeof value === 'object' && value !== null) {
                console.log('converting object to json:',value);
                return JSON.stringify(value); // Or access specific properties you want to render.
              }
              if(typeof value === 'string') {
                if(value.length > 100) {
                  return value.slice(0,100) + '...';
                }
              }
              return value;
            },
          };
        });

      setCustomColumns(columns);
    }
  }, [instances])

  return customColumns;
}