import React, {useEffect, useState} from 'react';
import './ShapeTable.scss';
import style from './ShapeTable.scss.json';
import { Icons } from './Icons';
import { cl } from 'lincd/lib/utils/ClassNames';
import Modal from './Modal';
import {
  Column,
  ColumnDef,
  PaginationState,
  Table,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { NodeShape } from 'lincd/lib/shapes/SHACL';

interface ShapeTable {
  data: any | ShapeSet<any>;
  columns: ColumnDef<any>[];
  toggleSelectAll: () => void;
  isRowSelected: (id: string) => boolean;
  selectedRows: string[];
  toggleRowSelection: (id: string) => void;
  isDeleteBtnShowing: boolean;
  shape?:NodeShape;
  pagination: { pageIndex: number; pageSize: number };
  totalItems: number;
  onPaginationChange: (newPagination: { pageIndex: number; pageSize: number }) => void;
  sorting: Array<any>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  isLight?: boolean;
  batchActionDropdown?: React.ReactNode;
}

function ShapeTable(
  {
  data,
  columns,
  toggleSelectAll,
  isRowSelected,
  selectedRows,
  toggleRowSelection,
  isDeleteBtnShowing,
  shape,
  pagination, // Receive pagination state
  totalItems, // Receive total items count
  onPaginationChange, // Receive pagination change handler
  sorting,
  onSortingChange,
  onSearchChange,
  isLight,
  batchActionDropdown
}: ShapeTable
) {

  const memoizedData = React.useMemo(() => {
    return [...data];
  }, [data]);

  const table = useReactTable({
    columns,
    data: memoizedData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: () => {
      onPaginationChange(pagination);
    },
    pageCount: Math.ceil(totalItems / pagination.pageSize), // Calculate total pages
    onSortingChange: onSortingChange,
  });


  return (
  <>
    <div className={style.tableWrapper}>
       <div className={style.action}>
       <div className={style.batchActionGroup}>
        {selectedRows.length > 0 && batchActionDropdown && (
          <>
            <p>Batch Actions</p>
            {React.cloneElement(batchActionDropdown as React.ReactElement<any>, { selectedRows })}
          </> 
        )}
        </div>
        <input
          type="text"
          className={style.searchBox}
          placeholder="Search..."
          onChange={(e) => onSearchChange(e.target.value)}
        />
       </div>
      <div className={cl(style.tableContainer, pagination.pageSize >= 20 && style.expanded )}>
        <table className={cl(style.table)}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={isLight ? style.tr : style.trDark}>
                <th className={cl(isLight ? style.th : style.thDark, style.sticky, style.stickyLeft)}>
                  <input type="checkbox" onChange={toggleSelectAll} />
                </th>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={isLight ? style.th : style.thDark}
                    >
                      {index === 0 ? (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      ) : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? style.canSort
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <Icons.UpArrow />,
                            desc: <Icons.DownArrow />,
                          }[header.column.getIsSorted() as string] ?? (
                            <Icons.LeftRightArrow />
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} data-id={row.original.id} className={isLight ? style.tr : style.trDark}>
                  <td className={cl(isLight ? style.td : style.tdDark, style.sticky, style.stickyLeft)}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        return toggleRowSelection(row.original.id)
                      }}
                      checked={isRowSelected(row.original.id)}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={cl(isLight ? style.td : style.tdDark)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={style.pagination}>
        <span className={style.pageInfo}>
          Page {pagination.pageIndex + 1} of <strong>{Math.ceil(totalItems / pagination.pageSize)}</strong>
        </span>
        <span className={style.pageInfo}>
          <input
            type="number"
            className={style.goToPageForm}
            value={pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              onPaginationChange({ ...pagination, pageIndex: page });
            }}
          />
        </span>
        <select
          className={style.selectPageRows}
          value={pagination.pageSize}
          onChange={(e) => {
            onPaginationChange({ ...pagination, pageSize: Number(e.target.value) });
          }}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
        <button
          className={style.paginationButton}
          onClick={() => onPaginationChange({ ...pagination, pageIndex: 0 })}
          disabled={pagination.pageIndex === 0}
        >
          <Icons.DoubleLeftArrow />
        </button>
        <button
          className={style.paginationButton}
          onClick={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex - 1 })}
          disabled={pagination.pageIndex === 0}
        >
          <Icons.SingleLeftArrow />
        </button>
        <button
          className={style.paginationButton}
          onClick={() => onPaginationChange({ ...pagination, pageIndex: pagination.pageIndex + 1 })}
          disabled={pagination.pageIndex >= Math.ceil(totalItems / pagination.pageSize) - 1}
        >
          <Icons.SingleRightArrow />
        </button>
        <button
          className={style.paginationButton}
          onClick={() => onPaginationChange({ ...pagination, pageIndex: Math.ceil(totalItems / pagination.pageSize) - 1 })}
          disabled={pagination.pageIndex >= Math.ceil(totalItems / pagination.pageSize) - 1}
        >
          <Icons.DoubleRightArrow />
        </button>
      </div>
    </div>
  </>
  );
}

export default ShapeTable;
