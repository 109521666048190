import React from 'react';
import ActionDropdown from './ActionDropdown';
import { useBatchActions } from '../hooks/useBatchActions'; // Custom hook to handle actions

interface AdSetActionDropdownProps {
  selectedRows: any[]; // Accept selectedRows as a prop
  onComplete?: () => void;
}
const AdSetActionDropdown: React.FC<AdSetActionDropdownProps> = ({ selectedRows, onComplete }) => {
  const { adSetBatchActions } = useBatchActions();

  return <ActionDropdown actions={adSetBatchActions} selectedRows={selectedRows} onComplete={onComplete}/>;
};

export default AdSetActionDropdown;
