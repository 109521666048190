import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {Literal} from 'lincd/lib/models';
import {Ad, AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {SelectedAction} from '../components/ActionDropdown';
import {NodeShape} from 'lincd/lib/shapes/SHACL';

interface ActionField
{
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  options?: {label: string;value: string}[]; // For select fields
}

export interface ActionConfig
{
  targetShape: NodeShape;
  actions: SingleActionConfig[];
}

interface SingleActionConfig
{
  value: string;
  method?: string; // Optional method field
  label: string;
  fields: ActionField[];
  onSubmit?: (formData: {[key: string]: any},selectedAction: SelectedAction,selectedItems?: string[]) => void; // Accept additionalData
}

export const useBatchActions = () => {
  const adBatchActions: ActionConfig = {
    targetShape: Ad.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Turn On',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Turn Off',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_status_guidance',
        method: 'statusGuidance',
        label: 'Change Status Guidance',
        fields: [
          {
            name: 'statusGuidance',
            label: 'Status Guidance',
            type: 'select',
            options: [
              {label: 'Keep On',value: 'keep_on'},
              {label: 'Keep Off (Not Profitable)',value: 'keep_off'},
              {label: 'Paused',value: 'paused'},
              {label: 'Untested',value: 'untested'},
            ],
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          const {statusGuidance} = formData;
          // action.targetShape = Ad.shape;
          action.parameters.add(new Literal(statusGuidance));
        },
      },
    ],
  };

  const adSetBatchActions: ActionConfig = {
    targetShape: AdSet.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Turn On',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Turn Off',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_bid_amount',
        method: 'setBidAmountInQuora',
        label: 'Change Bid Amount',
        fields: [
          {
            name: 'bidAmount',
            label: 'Bid Amount',
            type: 'number',
            placeholder: 'Enter bid amount...',
          }
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(new Literal(formData.bidAmount));
        },
      },
      {
        value: 'setOptimizedValue',
        method: 'setOptimizedValue',
        label: 'Set Optimized Value',
        fields: [
          {
            name: 'optimized',
            label: 'Optimized',
            type: 'boolean'
          }
        ],
      },
    ],
  };

  const approvalBatchActions = [
    {
      value: 'approve',
      method: 'approve',
      label: 'Approve',
      fields: [usernameField],
      onSubmit: (formData: any,selectedRows) => {
        return Server.call(packageName,'approveActions',formData,selectedRows);
        // Simulate a delay before making the actual backend call
        // return new Promise((resolve, reject) => {
        //   setTimeout(() => {
        //       Server.call(packageName, 'approveActions', formData, selectedRows)
        //       .then(response => {
        //           console.log('Backend call completed', response);
        //           resolve(response); // Resolve the promise after the backend call
        //       })
        //       .catch(error => {
        //           console.error('Backend call failed', error);
        //           reject(error); // Reject the promise in case of an error
        //       });
        //   }, 5000); // 3000 ms delay (3 seconds)
        //});
      },
    },
  ];

  return {adBatchActions, adSetBatchActions, approvalBatchActions};
};


const notesField: ActionField = {
  name: 'notes',
  label: 'Notes',
  type: 'textarea',
  placeholder: 'Enter notes here...',
};
const usernameField: ActionField = {
  name: 'userName',
  label: 'Name of User',
  type: 'text',
  placeholder: 'Enter your name...',
};
