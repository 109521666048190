import React from 'react';
import './Views.scss';
import * as styles from './Views.scss.json';
import {ROUTES} from '../routes';
import {generatePath} from 'react-router-dom';
import {DefaultLayout} from '../layout/DefaultLayout';
import {PageCard} from '../components/PageCard';
import {
  FaArrowCircleRight,
  FaCogs,
  FaLink,
  FaLock,
  FaSearch,
  FaUserFriends,
 FaAdversal } from 'react-icons/fa';
import { MdApproval } from "react-icons/md";

function Views() {

  return (
    <DefaultLayout>
      <h1>Views</h1>
      <div className={styles.viewsManagement}>
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'ad-management'
        })} icon={FaAdversal} label={'Ad Management'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'adset-management'
        })} icon={FaAdversal} label={'AdSet Management'} />
        <PageCard route={generatePath(ROUTES.view_detail.path, {
          detail: 'approval-page'
        })} icon={MdApproval} label={'Approval Page'} />
        {/*<li><Link to={generatePath(ROUTES.view_detail.path, {*/}
        {/*  detail: 'ad-management'*/}
        {/*})}>Ad Management</Link></li>*/}
        {/*<li><Link to={generatePath(ROUTES.view_detail.path, {*/}
        {/*  detail: 'approval-page'*/}
        {/*})}>Approval Page</Link></li>*/}
      </div>
    </DefaultLayout>
  )
}

export default Views