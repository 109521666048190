// src/components/AdActionDropdown.tsx
import React from 'react';
import ActionDropdown from './ActionDropdown';
import { useBatchActions } from '../hooks/useBatchActions'; // Custom hook to handle actions

interface AdActionDropdownProps {
  selectedRows: any[]; // Accept selectedRows as a prop
  onComplete?: () => void;
}
const AdActionDropdown: React.FC<AdActionDropdownProps> = ({ selectedRows, onComplete }) => {
  const { adBatchActions } = useBatchActions();

  return <ActionDropdown actions={adBatchActions} selectedRows={selectedRows} onComplete={onComplete}/>;
};

export default AdActionDropdown;
