import React, {useEffect, useState} from 'react'
import {DefaultLayout} from '../layout/DefaultLayout';
import { useParams } from 'react-router-dom';
import AdManagement from './AdManagement';
import AdSetManagement from './AdSetManagement';
import ApprovalPage from './ApprovalPage';

function ViewDetail() {
  const { detail } = useParams();

  const transformString = (string) => {
    return string
        .split('-') // Split the string by the hyphen
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
  }
  
  return (
    <DefaultLayout>
      <h1>View: {transformString(detail)} </h1>
      {detail === 'ad-management' && <AdManagement />}
      {detail === 'approval-page' && <ApprovalPage />}
      {detail === 'adset-management' && <AdSetManagement />}
    </DefaultLayout>
  )
}

export default ViewDetail