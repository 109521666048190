import React, { useState, useEffect } from 'react';
import ShapeTable from './ShapeTable';
import { Ad } from "titan-ads/lib/shapes/TitanShapes";
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PropertyShape } from 'lincd/lib/shapes/SHACL';
import { xsd } from 'lincd-xsd/lib/ontologies/xsd';
import { shacl } from 'lincd/lib/ontologies/shacl';
import { Shape } from 'lincd/lib/shapes/Shape';
import { Prefix } from 'lincd/lib/utils/Prefix';
import AdActionDropdown from '../components/AdActionDropdown';
import { NamedNode } from 'lincd/lib/models';

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface DynamicObject {
  [key: string]: any;
}

interface InstanceOverviewProps {
  customColumns?: CustomColumn[];
  instances: DynamicObject[];
  shape?: NodeShape;
  pagination: { pageIndex: number; pageSize: number };
  totalItems: number;
  onPaginationChange: (newPagination: { pageIndex: number; pageSize: number }) => void;
  sorting: Array<any>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  isLight?: boolean;
  batchActionDropdown?: (selectedRows:string[]) => React.ReactNode;
}

function InstanceOverview({
  customColumns,
  instances,
  shape,
  pagination,
  totalItems,
  onPaginationChange,
  sorting,
  onSortingChange,
  onSearchChange,
  isLight,
  batchActionDropdown
}: InstanceOverviewProps) {
  const [selectAll, setSelectAll] = useState(false); // State to track whether all rows are selected
  const [selectedRows, setSelectedRows] = useState<string[]>([]); // State to track selected row indices
  const [isDeleteBtnShowing, setIsDeleteBtnShowing] =
    React.useState<boolean>(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allRowUris = instances.map(i => i.id);
      setSelectedRows(allRowUris);
    } else {
      setSelectedRows([]);
    }
  };

  const toggleRowSelection = (uri: string) => {
    const selectedIndex = selectedRows.indexOf(uri);
    let newSelected: string[] = [];

    // If the row is already selected, remove it from the selectedRows array
    if (selectedIndex !== -1) {
      newSelected = selectedRows.filter((rowUri) => rowUri !== uri);
    } else {
      // If the row is not selected, add it to the selectedRows array
      newSelected = [...selectedRows, uri];
    }

    setSelectedRows(newSelected);
  };

  const isRowSelected = (id: string) => selectedRows.includes(id);

  let initialColumns: ColumnDef<Ad>[] = [
    {
      id: 'selection',
      cell: ({ row }) => (
        <input
          type="hidden"
          onChange={() => toggleRowSelection(row.id)}
          checked={isRowSelected(row.id)}

        />
      ),
    },
  ];

  let columnConfig;

  const cell = (info) => {
    const value = info.getValue();
    return value;
  };

  if (customColumns) {
    columnConfig = customColumns.map((config) => {
      return {
        id: config.label,
        header: config.property.label,
        accessorKey: config.label,
        cell: cell,
        accessorFn: config.renderCell,
      };
    });
    initialColumns.push(...columnConfig);

  } else {
    //dynamically define the columns based on the properties of the shape
    let propertiesOfShape = shape.getPropertyShapes();
    propertiesOfShape.forEach((propShape) => {
      // default column configuration
      columnConfig = {
        id: propShape.label,
        accessorKey: propShape.label,
        cell: (info) => {
          let value = info.getValue();
          if(typeof value === 'undefined') {
            return '';
          }
          let nodeKind = propShape.nodeKind;
          if (
            nodeKind === shacl.IRI ||
            nodeKind === shacl.BlankNode ||
            nodeKind === shacl.BlankNodeOrIRI
          ) {
            if (propShape.maxCount === 1) {
              //values are expected to be nodes (NamedNode or BlankNode), which will be returned as Shape or ShapeSet
              if (value instanceof Shape) {
                return Prefix.toPrefixed(value.uri);
              } else {
                console.warn(
                  'Was expecting a single Shape value, but instead got:',
                  value,
                  propShape,
                );
              }
            } else {
              if (value instanceof ShapeSet) {
                return [...value]
                  .map((shapeValue) => Prefix.toPrefixed(shapeValue.uri))
                  .join(', ');
              } else {
                console.warn(
                  'Was expecting a ShapeSet (multiple values), but instead got:',
                  value,
                  propShape,
                );
              }
            }
          } else if (nodeKind === shacl.Literal) {
            if (propShape.maxCount === 1) {
              if (!Array.isArray(value)) {
                return <CellValue value={value} propShape={propShape} />;
              } else {
                //if the value is an array
                console.warn(
                  'Was expecting a single value, but instead got an array of values',
                  propShape,
                  value,
                );
              }
            } else {
              //we are expecitng multiple values in an array
              if (Array.isArray(value)) {
                return (
                  <span>
                    {value.map((singleValue) => {
                      return (
                        <CellValue value={singleValue} propShape={propShape} />
                      );
                    })}
                  </span>
                );
              } else {
                //if the value is an array
                console.warn(
                  'Was expecting a multiple values as an array, but instead got something else',
                  propShape,
                  value,
                );
              }
            }
          } else {
            console.warn(
              'Unknown nodeKind or nodeKind not defined: ',
              propShape,
              nodeKind,
            );
          }
        },
        header: propShape.label
          .replace(/([A-Z])/g, ' $1')
          .replace(/_/g, ' ')
          .replace(/^./, (char) => char.toUpperCase()),
        footer: (props) => props.column.id,
      };

      initialColumns.push(columnConfig);
    });
  }

  const columns = React.useMemo<ColumnDef<Ad>[]>(
    () => initialColumns,
    [],
  );


  return (
    <ShapeTable
      data={instances}
      columns={columns}
      toggleSelectAll={toggleSelectAll}
      isRowSelected={isRowSelected}
      selectedRows={selectedRows}
      toggleRowSelection={toggleRowSelection}
      isDeleteBtnShowing={isDeleteBtnShowing}
      shape={shape}
      pagination={pagination} // Pass pagination state
      totalItems={totalItems} // Pass the total number of items
      onPaginationChange={onPaginationChange} // Pass handler to update pagination
      sorting={sorting}
      onSortingChange={onSortingChange}
      onSearchChange={onSearchChange}
      isLight={isLight} // Pass isLight to ShapeTable
      batchActionDropdown={batchActionDropdown && batchActionDropdown(selectedRows)}
      // batchActionDropdown={<AdActionDropdown selectedRows={selectedRows} />}
    />
  );
}

export const CellValue = ({
  value,
  propShape,
}: {
  value: any;
  propShape: PropertyShape;
}) => {
  //values are expected to be Literal
  let datatype = propShape.datatype;
  if (datatype === xsd.date) {
    if (value instanceof Date) {
      return value.toLocaleString();
    } else {
      console.warn(
        'This property should have Date values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype === xsd.boolean) {
    if (typeof value === 'boolean') {
      return value; // === "true" ?
    } else {
      console.warn(
        'This property should have Boolean values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype === xsd.integer || datatype === xsd.decimal) {
    if (typeof value === 'number') {
      if(isNaN(value) || value === null) {
        return '';
      }
      return value;
    } else {
      console.warn(
        'This property should have Number/Integer values but returned something else:',
        propShape,
        value,
      );
    }
  } else if (datatype && datatype !== xsd.string) {
    console.warn('Datatype not supported yet:', datatype, propShape, value); 
  } else {
    //by default the datatype is either xsd.string or undefined, then we expect a string
    if (typeof value === 'string') {
      return value;
    } else if(typeof value === 'undefined') {
      return '';
    } else if (value === null) {
      return '';
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value); // for debugging purposes or handle the object appropriately.
    } else {
      console.warn(
        'This property should have string values but returned something else:',
        propShape,
        value,
      );
    }
  }
};

export { InstanceOverview };
